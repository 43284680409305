<script setup lang="ts">
import {
	Sheet,
	SheetContent,
	SheetHeader,
	SheetTitle,
	DialogHeader,
	DialogTitle,
	Dialog,
	DialogContent,
	DialogTrigger,
} from '@laam/ui/base';
import LazyImage from '~/components/LazyImage.vue';
const LoginBox = defineAsyncComponent(() => import('./LoginBox.vue'));
// Schema defination
import { useForm } from 'vee-validate';
import { useLoganStore } from '~/stores/logan';
import countries from '~/utils/countries.json';
import { LoganSchema } from './types';
import { useToast } from '@laam/ui/base';
import { createReusableTemplate } from '@vueuse/core';
import { PhWarningCircle } from '@phosphor-icons/vue';
import { watchDebounced } from '@vueuse/core';
import { useGlobalStore } from '~/stores/global';
import { isMobileView } from '~/utils/helpers';
import { storeToRefs } from 'pinia';
import { useCountryCode } from '~/stores/country';

interface Logan {
	renderFormOnly: boolean;
	heading: string;
	subHeading: string;
	otpHelpMsg: string;
	showConfirmationText: boolean;
	showSignInWithButtons: boolean;
	signInWithText: string;
	autoFillData?: {
		phone: string;
		email: string;
	};
	hideDialogTrigger?: boolean;
}

const props = withDefaults(defineProps<Logan>(), {
	renderFormOnly: false,
	heading: '',
	subHeading: '',
	otpHelpMsg: '',
	showConfirmationText: false,
	showSignInWithButtons: false,
	signInWithText: '',
	fetchFromCheckout: false,
	autoFillData: undefined,
	hideDialogTrigger: false,
});

const { toast } = useToast();
const [UseTemplate, LoganForm] = createReusableTemplate();
const LoganStore = useLoganStore();
const { requiresSignIn } = storeToRefs(LoganStore);
const countryCodeStore = useCountryCode();
const { countryCode } = storeToRefs(countryCodeStore);

const { storeType, headerLogo } = useGlobalStore();
const otpMsg = ref();
const otpSubHeading = ref();

const { handleSubmit, setErrors, setValues, values, errors } = useForm({
	validationSchema: LoganSchema,
	validateOnMount: false,
	initialValues: {
		phoneCountryCode: 'PK',
		email: '',
		usingEmail: false,
		otpCode: '',
		phoneNumber: '',
		phonePlaceholderPattern: '',
		phoneRegexPattern: '',
		subscribeToNewsLetter: storeType === 'MARKETPLACE',
	},
});

const subHeadingText = (otpMethod: string) => {
	if (otpMethod === 'email') {
		return 'To confirm your email, please enter the OTP we sent to your email ID.';
	} else {
		return 'To confirm your phone number, please enter the OTP we sent on your entered number.';
	}
};

// FIXME: no need to provide these, use already offered hooks from vee-validate
provide('values', values);
provide('setValues', setValues);

const onSubmit = () => {
	const isSubscribed = localStorage.getItem('isSubscribed')
		? (JSON.parse(localStorage.getItem('isSubscribed')!) as boolean)
		: false;
	if (isSubscribed) {
		setValues({
			subscribeToNewsLetter: false,
		});
	}
	handleSubmit((values) => {
		const countryCode = values.usingEmail
			? ''
			: countries[values.phoneCountryCode as keyof typeof countries].phone[0];
		if (LoganStore.showOtpInputBox === false || LoganStore.resendOtp == true) {
			if (values.usingEmail) {
				const email = values.email;
				if (!email) {
					LoganStore.triggerInvalidInput();
					throw new Error('No email entered');
				}
				if (LoganStore.verifyEmail(email) === false) {
					return;
				}
				LoganStore.sendOtp(values.usingEmail, email);
				otpMsg.value = 'Did not get an email? Please check your junk folder.';
				otpSubHeading.value = subHeadingText('email');
			} else {
				const phNumber = values.phoneNumber?.replace(/^0|\(|\)|-/g, '');
				if (!phNumber) {
					LoganStore.triggerInvalidInput();
					throw new Error('No Phone number entered');
				}
				if (LoganStore.verifyPhone(phNumber) === false) {
					return;
				}
				const phoneNumber = '+' + countryCode + phNumber;
				LoganStore.sendOtp(values.usingEmail, phoneNumber);
				otpMsg.value = 'If you do not receive an OTP, kindly SMS MNP to 81478.';
				otpSubHeading.value = subHeadingText('phone');
			}
			LoganStore.resendOtp = false;
		} else {
			const otpCode = values.otpCode;
			if (!otpCode) {
				LoganStore.triggerInvalidOtp();
				LoganStore.triggerLoganAlert('No code entered');
				return;
				// throw new Error('No otp entered');
			}
			if (values.usingEmail) {
				const email = values.email;
				if (!email) {
					LoganStore.triggerInvalidInput();
					throw new Error('No email entered');
				}
				LoganStore.enterOtp(
					values.usingEmail,
					email,
					otpCode,
					values.subscribeToNewsLetter,
				);
			} else {
				const phNumber = values.phoneNumber?.replace(/^0|\(|\)|-/g, '');
				if (!phNumber) {
					LoganStore.triggerInvalidInput();
					throw new Error('No Phone number entered');
				}
				const phoneNumber = '+' + countryCode + phNumber;
				LoganStore.enterOtp(
					values.usingEmail,
					phoneNumber,
					otpCode,
					values.subscribeToNewsLetter,
				);
			}
		}
	})();
};
const emits = defineEmits(['handleUsingEmail']);
const handleAutoFillData = () => {
	if (props.autoFillData) {
		const split = splitPhoneString(props.autoFillData.phone);
		if (split.prefix && split.prefix === '92') {
			setValues({
				usingEmail: false,
				phoneNumber: '0' + split.number,
			});
			emits('handleUsingEmail', values.usingEmail);
		} else {
			setValues({
				usingEmail: true,
				email: props.autoFillData.email,
			});
			emits('handleUsingEmail', values.usingEmail);
		}
		onSubmit();
	}
};
onMounted(() => {
	if (countryCode.value !== 'PK') {
		setValues({
			usingEmail: true,
		});
	}
	handleAutoFillData();
});

watch(
	() => props.autoFillData,
	() => {
		handleAutoFillData();
	},
);
// watcher to reset states
// watch(
// 	() => values.usingEmail,
// 	() => {
// 		values.phoneNumber = '';
// 		values.email = '';
// 		LoganStore.showOtpInputBox = false;
// 		LoganStore.invalidInput = false;
// 	},
// );

// watcher to set error
// watch(
// 	() => LoganStore.invalidInput,
// 	(n) => {
// 		if (n) {
// 			setErrors({
// 				phoneNumber: 'The phone field must be at least 10 characters.',
// 				email: 'Please enter a valid email address.',
// 			});
// 		} else {
// 			setErrors({
// 				phoneNumber: undefined,
// 				email: undefined,
// 			});
// 		}
// 	},
// );
// watcher to toast
watch(
	() => LoganStore.showAlert,
	(n) => {
		if (n) {
			toast({
				title: LoganStore.alertMessage,
				variant: 'info',
				icon: h(PhWarningCircle, {
					color: 'white',
					size: 24,
				}),
			});
		}
	},
);

watchDebounced(
	errors,
	(newVal) => {
		if (Object.keys(newVal || {}).length > 0) {
			setTimeout(() => {
				setErrors({
					email: undefined,
					phoneNumber: undefined,
				});
			}, 3000);
		}
	},
	{
		debounce: useRuntimeConfig().public.impressionDebounceTime,
	},
);

watch(
	() => values.usingEmail,
	(newValue) => {
		if (newValue === undefined) {
			// when modal is closed these values are set to undefined, hence the need to update them in a watcher
			// if country code is pk and we close the modal set using email to false
			if (countryCode.value === 'PK') {
				setValues({
					usingEmail: false,
					subscribeToNewsLetter: true,
				});
			}
			// if country code is not pk we set  using email to true
			else {
				setValues({
					usingEmail: true,
					subscribeToNewsLetter: true,
				});
			}
		}
		LoganStore.showOtpInputBox = false;
	},
);
</script>
<template>
	<div v-if="!renderFormOnly" class="w-full">
		<UseTemplate>
			<div v-if="requiresSignIn" class="warning p-3xl bg-warning-50">
				<h1 class="font-semibold text-gray-700 text-md">
					This feature requires signing in.
				</h1>
				<p class="text-xs text-gray-600">
					Sign in or create an account to get started.
				</p>
			</div>
			<form
				id="loganForm"
				class="flex flex-col items-center"
				@submit.prevent="onSubmit"
			>
				<LoginBox
					heading="Sign up or log in"
					:sub-heading="otpSubHeading"
					:otp-help-msg="otpMsg"
					:show-confirmation-text="true"
					:show-sign-in-with-button="countryCode === 'PK'"
					:form-name="'loganForm'"
				/>
			</form>
		</UseTemplate>
		<Sheet
			v-if="isMobileView()"
			:open="LoganStore.showLoginSheet"
			@update:open="LoganStore.showLoginSheet = !LoganStore.showLoginSheet"
		>
			<SheetContent
				class="w-full h-full"
				side="bottom"
				:on-open-auto-focus="(e) => e.preventDefault()"
				:on-close-auto-focus="(e) => e.preventDefault()"
			>
				<SheetHeader class="flex">
					<SheetTitle>
						<LazyImage
							v-if="storeType === 'MARKETPLACE'"
							src="https://cdn.shopify.com/s/files/1/2337/7003/files/laam_logo_7509750b-f46a-4cca-afb2-54b558126e81.png?v=1599740777"
							alt="Laam Logo"
							class="w-[112px] h-[36px]"
						/>
						<LazyImage
							v-else-if="headerLogo"
							:src="headerLogo"
							alt="Logo"
							class="w-fit"
						/>
					</SheetTitle>
				</SheetHeader>
				<LoganForm />
			</SheetContent>
		</Sheet>
		<Dialog
			v-else
			:open="LoganStore?.showLoginSheet"
			@update:open="LoganStore.showLoginSheet = !LoganStore.showLoginSheet"
		>
			<DialogTrigger v-if="!hideDialogTrigger" data-testid="login-button">
				<div class="hidden bg-white lg:flex p-lg login_button">
					<slot />
				</div>
			</DialogTrigger>
			<DialogContent
				class="min-w-[600px]"
				class-z-index="z-[200]"
				data-testid="login-dialog"
			>
				<DialogHeader class="flex">
					<DialogTitle>
						<LazyImage
							v-if="storeType === 'MARKETPLACE'"
							src="https://cdn.shopify.com/s/files/1/2337/7003/files/laam_logo_7509750b-f46a-4cca-afb2-54b558126e81.png?v=1599740777"
							class="w-[112px] h-[36px]"
							alt="Laam Logo"
						/>
						<LazyImage
							v-else-if="headerLogo"
							:src="headerLogo"
							alt="Logo"
							class="w-fit"
						/>
					</DialogTitle>
				</DialogHeader>
				<LoganForm />
			</DialogContent>
		</Dialog>
	</div>
	<form
		v-else
		id="loganForm"
		class="flex flex-col items-center"
		@submit.prevent="onSubmit"
	>
		<LoginBox
			:heading="heading"
			:sub-heading="subHeading"
			:otp-help-msg="otpHelpMsg"
			:show-confirmation-text="showConfirmationText"
			:show-sign-in-with-button="
				(LoganStore.showOtpInputBox === false || showSignInWithButtons) &&
				countryCode === 'PK'
			"
			:sign-in-with-text="signInWithText"
			:form-name="'loganForm'"
			class="p-none"
		/>
	</form>
</template>
